import { Directive } from '@angular/core';

import { CheckboxStatesEnum } from '../types/checkbox-states.enum';

export interface TreeChecklistSelectTemplateContext<T> {
  $implicit: T;
  selectionState: CheckboxStatesEnum;
  onSelectToggle: (checked: boolean) => void;
  isLeaf?: boolean;
}

@Directive({
  selector: '[ngpTreeChecklistSelect]',
  standalone: true,
})
export class TreeChecklistSelectTemplateDirective {}
